export default [
                      
  { name: '九揚盧登堡', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: 0 },
  { name: '精彩在蘆洲', imgSrc: '', subTitle: '', section: 'section1-1', svgSrc: '', offset:-100 , mobileOffset: -100 },
  { name: '卓越品牌力', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', mobileOffset: 0 },
  { name: '移居最首選', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '', mobileOffset: 0 },
  { name: '新學霸搖籃', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', 
  mobileOffset: 0 },
  { name: '捷運300米', imgSrc: '', subTitle: '', section: 'section5-2', svgSrc: '', 
  mobileOffset: 0 },
  { name: '明媚好生活', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', 
  mobileOffset: 0 },
  { name: '健康陽光宅', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '', 
  mobileOffset: 0 },
  { name: '經典永流傳', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '', 
  mobileOffset: 0 },
  // { name: '', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '', mobileOffset: 0, type: 'btn', color: 'orange' },
  // { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, type: 'btn', color: 'blue' },
  // { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, type: 'btn', color: 'green' },
]
