import { isMobile } from '../utils/index'
export default {
  address: '接待中心：台灣新北市蘆洲區三民路557號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3613.2842336027143!2d121.4603302459163!3d25.09223807128095!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a8b350bcd1cf%3A0x3f2e6514f1b1c0ad!2zMjQ35paw5YyX5biC6JiG5rSy5Y2A5LiJ5rCR6LevNTU36Jmf!5e0!3m2!1szh-TW!2stw!4v1620028059816!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/EhW47pbfTUJXeVbp7',
  phone: '02-8287-6888',
  fbLink: 'https://www.facebook.com/%E4%B9%9D%E6%8F%9A%E7%9B%A7%E7%99%BB%E5%A0%A1-102628871717373/',
  fbMessage: 'https://m.me/102628871717373',
  caseName: '九揚盧登堡',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '盛泓開發股份有限公司'],
    ['營造公司', '昇北營造股份有限公司'],
    ['企劃銷售', '新富利廣告有限公司'],
    ['景觀設計', '六國景觀'],
  ],

  gtmCode: ['MBMBGP4', 'NX6NPQQ'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: isMobile ? '預約專線' : '預約專線',
    subTitle: '｜相約九揚 蘆洲最美的家｜'
  }
}
